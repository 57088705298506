export default {
  // The blog's title as it appears in the layout header, and in the document
  // <title> tag.
  title: "the blog system",

  author: "Justin Carter",
  description: "A blog generated by gpt-3",

  // The number of posts to a page on the site index.
  indexPageSize: 10,
}
