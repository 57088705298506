import React from 'react'
import styles from './Bio.module.css'

function Bio(props) {
  const imageIndex = Math.round(Math.random() * 16);
  const photoURL = `/jasper.jpg`;

  return (
    <div className={`
      ${styles.Bio}
      ${props.className || ''}
    `}>
      <img src={photoURL} alt="Me" />
      <p>
        Blog posts generated by{' '}
        <a href="https://openai.com/">GPT-3</a>.
        <br />
        Please don't take this seriously.
      </p>
    </div>
  )
}

export default Bio
